// extracted by mini-css-extract-plugin
export var wrapper = "g_5";
export var backgroundarea = "g_6";
export var blog = "g_7";
export var contents = "g_8";
export var row = "g_9";
export var row2 = "g_bb";
export var row3left = "g_bc";
export var row3right = "g_bd";
export var row3 = "g_bf";
export var row3Bottom = "g_bg";
export var gazou = "g_bh";
export var comments = "g_bj";
export var writer = "g_bk";
export var writergazou = "g_bl";
export var introduce = "g_bm";
export var none = "g_bn";
export var icon = "g_t";
export var article = "g_bp";
export var gridContainer = "g_bq";
export var content = "g_br";