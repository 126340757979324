import React from "react";
import {DefaultLayout} from "../organisms/DefaultLayout";
import * as styles from "../styles/careers/pcblog.module.scss"

export default () =>
  <DefaultLayout>
    <div className={styles.backgroundarea}>


      <div className={styles.blog}>
        <div className={styles.contents}>
          <div className={styles.wrapper}>

            <h2>設立１年販売個数【４万個突破】通販事業部を紹介！</h2>
            <div className={styles.row}>
              <p>2020/11/19</p>
              <p>2020/12/11</p>
              <p><span>社内報</span></p>
              <p>呉山 梨奈</p>
            </div>
            <div className={styles.row2}>
              <p><span className="fa fa-facebook"></span>⑰社内報</p>
              <p>D2C事業</p>
              <p>Web広告</p>
              <p>イルミルド製薬株式会社</p>
              <p>インタビュー</p>
              <p>イルミルド製薬株式会社</p>
              <p>D2C事業</p>
              <p>Web広告</p>
            </div>
            <div className={styles.row3}>
              <div className={styles.row3left}>
                <img src="https://placehold.jp/130x60.jpg" />
                <img src="https://placehold.jp/130x60.jpg" />
                <img src="https://placehold.jp/130x60.jpg" />
                <img src="https://placehold.jp/130x60.jpg" />
              </div>
              <div className={styles.row3right}>
                <img src="https://placehold.jp/100x62.jpg" />
                <img src="https://placehold.jp/100x62.jpg" />
              </div>
            </div>


              <img className={styles.gazou} src="https://placehold.jp/800x600.jpg" />

            <div className={styles.comments}>
              <p>皆さま、こんにちは。</p>
              <p>紅葉が一段と色を増す季節となりましたが、お元気にお過ごしでしょうか？</p>
              <br/>
              <p>今回は<span>D2C事業*</span>を行う、<span>通販事業部の大鶴事業部長にインタビュー</span>を行いました。</p>
              <p>Direct-to-Consumer:自社で商品を企画・製造し、独自の販売チャネルでお客様に商品を販売する。</p>
              <br/>
              <p>通販事業部は、今年の６月で設立１年が経ち、今では<span>販売個数４万個を突破</span>。</p>
              <p><span>他社にはない事業部の強み</span>や、これまで<span>どのような商品を企画・販売してきたのか</span>などお聞きしました</p>
              <p>是非とも最後までご覧いただけますと幸いです。</p>
              <br/>
              <p>【聞き手：広報担当　呉山】</p>
              <div className={styles.row3Bottom}>
                <div className={styles.row3left}>
                  <img src="https://placehold.jp/130x60.jpg" />
                  <img src="https://placehold.jp/130x60.jpg" />
                  <img src="https://placehold.jp/130x60.jpg" />
                  <img src="https://placehold.jp/130x60.jpg" />
                </div>
                <div className={styles.row3right}>
                  <img src="https://placehold.jp/100x62.jpg" />
                  <img src="https://placehold.jp/112x62.jpg" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className={styles.writer}>
        <div className={styles.wrapper}>
          <h3>ライター紹介</h3>
          <div className={styles.writergazou}>
          <img src="https://placehold.jp/100x100.jpg" />
          </div>
          <div className={styles.introduce}>
            <h4>呉山 梨奈</h4>
            <p>皆さま、お世話になります。</p>
            <p>Suprieve株式会社で広報担当をしております、呉山と申します。</p>
            <br/>
            <p>この度は社内報をご覧いただき誠にありがとうございます。</p>
            <br/>
            <p>少しばかり私の紹介をさせてください！</p>
            <p>弊社に2018年４月に新卒で終車、入社当時から「くれてぃ」や「くれちゃん」と呼ばれることが多く、根っからの大阪っ子です！</p>
            <br/>
            <p>「インドアでもありアウトドアタイプでもある」</p>
            <p>アニメも漫画もショッピングも大好きです！</p>
            <br/>
            <p>▼Twitter以外にもinstagramもはじめました。</p>
            <p className={styles.none}>https://www.instagram.com/lina_flower_1102/</p>
            <p>今後とも、何卒よろしくお願い申し上げます！</p>
          </div>

          <div className={styles.icon}>
            <img src="https://placehold.jp/40x40.jpg" />
            <img src="https://placehold.jp/40x40.jpg" />
          </div>


        </div>
      </div>

      <div className={styles.article}>
        <div className={styles.wrapper}>
          <h3>関連記事</h3>


          <div className={styles.gridContainer}>


            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>

            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
            <div className={styles.content}>
              <img src="https://placehold.jp/250x145.jpg" />
              <p>21新卒の内定式開催！コシノジュンコ氏とコラボ！Amazonで日本セラー初って！？【その他NEWS</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </DefaultLayout>;